import React, { useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";
import Header from "../components/header/header.js";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import { useStaticQuery, graphql } from "gatsby";

import "../assets/styles/pages/_our-customers.scss";

const OurCustomers = () => {
  const theme = useContext(ThemeContext);
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "hotelLogos" } }) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `);

  const HOTEL_LINKS = [
    { name: "logo-aganoor", link: "https://www.hotelaganoor.it/" },    
    { name: "logo-bramante", link: "https://www.hotelbramante.it/" },
    { name: "logo-canalicchio", link: "https://www.canalicchiodisoprawinerelais.com/" },
    { name: "logo-domus-pacis", link: "https://www.domuspacis.it/" },
    { name: "logo-domus-volumnia", link: "https://www.domusvolumnia.it/" },
    { name: "logo-frate-sole", link: "https://www.hotelfratesole.com/" },
    { name: "logo-gattapone", link: "http://www.hotelgattapone.net/" },
    { name: "logo-grand-hotel-elite", link: "https://www.grandhotelelite.it/" },
    { name: "logo-green-village", link: "https://greenvillage-camping-hotel-assisi.com/" },
    { name: "logo-hotel-pax", link: "https://www.hotelpaxassisi.com/" },
    { name: "logo-mom-assisi", link: "https://www.hotelmomassisi.it/" },
    { name: "logo-palazzo", link: "http://www.hotelilpalazzo.it/" },
    { name: "logo-sorella-luna", link: "http://www.hotelsorellaluna.it/" },
    { name: "logo-treesse", link: "http://www.countryhousetreesse.com/" },    
    { name: "logo-franceschini", link: "https://www.palazzofranceschini.com/" },    
    { name: "logo-villa-americana", link: "https://www.villaamericana.it/" },    
    { name: "logo-poggio-fiorito", link: "http://www.poggiofioritoassisi.com/" },    
    { name: "logo-monaciano", link: "https://www.monaciano.com/" },    
    { name: "logo-nun", link: "https://www.nunassisi.com/" },    
    { name: "logo-madonna-delle-rose", link: "https://www.madonnadellerose.com/" },    
    { name: "logo-roccaporena", link: "https://www.hotelroccaporena.com/" },    
    { name: "logo-re-artu-assisi", link: "https://www.reartuassisi.com/" },    
    { name: "logo-hotel-porta-marmorea", link: "https://www.hotelportamarmorea.it/" },    
    { name: "logo-isola-verde", link: "https://www.isolaverderesort.it/" },    
    { name: "logo-viole", link: "https://www.violecountryhotel.it/" },    
    { name: "logo-mare-verde", link: "https://www.mareverdevillaggio.it/" },    
    { name: "logo-santa-fortunata", link: "https://www.santafortunata.eu/" },    
    { name: "logo-ripa-relais", link: "https://riparelais.com/" },    
    { name: "logo-la-terrazza", link: "https://www.laterrazzahotel.it/" },    
    { name: "logo-roccafiore", link: "https://roccafiore.it/" },        
  ];

  const HOTEL_LOGOS = data.allFile.edges.map((i) => i.node);
  
  const HOTEL_LOGOS_AND_LINKS = HOTEL_LOGOS.map(i => {
    let logosAndLinksMatching = HOTEL_LINKS.find((e) => e.name === i.name);
    return {
      ...i,
      link: logosAndLinksMatching ? logosAndLinksMatching.link : "/",
    };
  });


  return (
    <>
      <Header title="I nostri clienti" />
      <div className="our-customers">
        <Swiper
          modules={[Pagination, Autoplay]}
          spaceBetween={0}
          slidesPerView={theme.isMobile ? 1 : 6}
          slideToClickedSlide={true}
          centeredSlides={true}
          autoplay={{ delay: 2500, disableOnInteraction: false }}
          loop={true}
        >
          {HOTEL_LOGOS_AND_LINKS.map((i, index) => (
            <SwiperSlide key={index}>
              <div className="hotelLogo">
                <a href={i.link} rel="noreferrer" target="_blank">
                  <img src={"../../" + i.publicURL} alt={i.name} />
                </a>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="section" />
      </div>
    </>
  );
};

export default OurCustomers;
